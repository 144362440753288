import { takeLatest, put } from 'redux-saga/effects';

import {
  fetchDocumentsRequest,
  fetchDocumentsSuccess,
  fetchDocumentsError,
} from './index';
import { coreClient as client } from '../../helpers/requests/client';
import { getResponseData } from '../../helpers/requests/bennieFetch';

export function* fetchDocuments() {
  try {
    const response = yield client(`/v1/profile/enrollmentdocuments`);

    const data = getResponseData(response);

    yield put(fetchDocumentsSuccess(data));
  } catch (error) {
    yield put(fetchDocumentsError(error));
  }
}

export function* fetchDocumentsWatcher() {
  yield takeLatest(fetchDocumentsRequest, fetchDocuments);
}
