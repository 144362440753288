import { createSelector } from 'reselect';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _compact from 'lodash/compact';

// getState takes a path in the state and returns a function
// that accepts the state and returns the value at that path.
const getState = path => state => _get(state, `findMedicalCare.${path}`);

const getCurrentSearchParams = getState('currentSearchParams');
const getErrorDeletingSavedFacility = getState('errorDeletingSavedFacility');
const getErrorDeletingSavedProvider = getState('errorDeletingSavedProvider');
const getErrorFetchingSavedFacilities = getState(
  'errorFetchingSavedFacilities',
);
const getErrorFetchingSavedProviders = getState('errorFetchingSavedProviders');
const getErrorSavingFacility = getState('errorSavingFacility');
const getErrorSavingProvider = getState('errorSavingProvider');
const getErrorFetchingItem = getState('errorFetchingItem');
const getHasFetchedSearchResults = getState('hasFetchedSearchResults');
const getIsFetchingSavedFacilities = getState('isFetchingSavedFacilities');
const getIsFetchingSavedProviders = getState('isFetchingSavedProviders');
const getIsFetchingSearchResults = getState('isFetchingSearchResults');
const getIsSettingSavedProvider = getState('isSettingSavedProvider');
const getIsSettingSavedLocation = getState('isSettingSavedLocation');
const getIsFetchingItem = getState('isFetchingItem');
const getSavedFacilities = getState('savedFacilities');
const getSavedProviders = getState('savedProviders');
const getSearchPagination = getState('searchPagination');
const hasFetchedSavedFacilitiesSelector = getState('hasFetchedSavedFacilities');
const hasFetchedSavedProvidersSelector = getState('hasFetchedSavedProviders');

const getSearchResults = createSelector(
  getState('searchResults'),
  getState('searchResultsOrder'),
  (searchResults, searchResultsOrder) => {
    if (!searchResults) return [];

    const results = searchResultsOrder.map(id => searchResults[id]);

    return _uniqBy(_compact(results), 'id');
  },
);

const getCurrentPageResults = createSelector(
  getState('searchResults'),
  getSearchPagination,
  getState('searchResultsOrder'),
  (searchResults, searchPagination, searchResultsOrder) => {
    if (!searchResults) return [];
    const { page, pageSize } = searchPagination;
    const results = searchResultsOrder.map(id => searchResults[id]);

    return _uniqBy(results.slice((page - 1) * pageSize, page * pageSize), 'id');
  },
);

const getResultById = id =>
  createSelector(
    getState('searchResults'),
    searchResults => searchResults[id],
  );

const getResultsByIds = ids =>
  createSelector(
    getState('searchResults'),
    searchResults => ids.map(id => searchResults[id]),
  );

const getSortedByDate = selector =>
  createSelector(
    selector,
    entityArr => {
      if (!entityArr) return [];
      return _values(entityArr).sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
    },
  );
const getSortedSavedFacilities = getSortedByDate(getSavedFacilities);
const getSortedSavedProviders = getSortedByDate(getSavedProviders);

const getSavedItemIds = createSelector(
  getSortedSavedFacilities,
  getSortedSavedProviders,
  (savedFacilities, savedProviders) => [
    ...savedFacilities.map(({ id }) => id),
    ...savedProviders.map(({ id }) => id),
  ],
);

const getIsSavedProviderById = createSelector(
  getSortedSavedProviders,
  (_, props) => props.match?.params?.provider,
  (savedProviders, providerId) =>
    savedProviders.find(({ id }) => id === providerId) !== undefined,
);

const canSaveProvider = state =>
  state.findMedicalCare.hasFetchedSavedProviders &&
  !state.findMedicalCare.isFetchingSavedProviders &&
  Object.keys(state.findMedicalCare.savedProviders).length < 10;

const canSaveFacility = state =>
  state.findMedicalCare.hasFetchedSavedFacilities &&
  !state.findMedicalCare.isFetchingSavedFacilities &&
  Object.keys(state.findMedicalCare.savedFacilities).length < 10;

const getFetchingProviderError = state => ({
  shouldDelete: state.findMedicalCare.shouldDeleteProvider,
  error: state.findMedicalCare.errorFetchingProvider,
});

export {
  canSaveProvider,
  canSaveFacility,
  getCurrentPageResults,
  getCurrentSearchParams,
  getErrorDeletingSavedFacility,
  getErrorDeletingSavedProvider,
  getErrorFetchingSavedFacilities,
  getErrorFetchingSavedProviders,
  getErrorFetchingItem,
  getErrorSavingFacility,
  getErrorSavingProvider,
  getFetchingProviderError,
  getHasFetchedSearchResults,
  getIsFetchingSavedFacilities,
  getIsFetchingSavedProviders,
  getIsFetchingSearchResults,
  getIsFetchingItem,
  getIsSavedProviderById,
  getIsSettingSavedProvider,
  getIsSettingSavedLocation,
  getResultById,
  getResultsByIds,
  getSavedFacilities,
  getSavedItemIds,
  getSavedProviders,
  getSearchPagination,
  getSearchResults,
  getSortedSavedFacilities,
  getSortedSavedProviders,
  hasFetchedSavedFacilitiesSelector,
  hasFetchedSavedProvidersSelector,
};
