import { createSlice } from '@reduxjs/toolkit';

import { setState } from '../../../helpers/store';

const ssoSlice = createSlice({
  name: 'sso',
  initialState: {
    isFetchingConfig: false,
    isCreatingConfig: false,
    isDeletingConfig: false,
    isEnablingConfig: false,
    config: null,
    errors: {
      fetchConfig: null,
      createConfig: null,
      deleteConfig: null,
      enableConfig: null,
    },
  },
  reducers: {
    fetchSsoConfigRequest(state) {
      const errors = {
        ...state.errors,
        fetchConfig: null,
      };

      setState(state, {
        isFetchingConfig: true,
        errors,
      });
    },
    fetchSsoConfigSuccess(state, { payload }) {
      setState(state, {
        isFetchingConfig: false,
        config: payload.response || null,
        // response returns empty string but it should not be set as an empty string, config is an object
      });
    },
    fetchSsoConfigError(state, { payload }) {
      const errors = {
        ...state.errors,
        fetchConfig: payload.error,
      };

      setState(state, {
        isFetchingConfig: false,
        errors,
      });
    },
    createSsoConfigRequest(state) {
      const errors = {
        ...state.errors,
        createConfig: null,
      };

      setState(state, {
        isCreatingConfig: true,
        errors,
      });
    },
    createSsoConfigSuccess(state, { payload }) {
      setState(state, {
        isCreatingConfig: false,
        config: payload.response,
      });
    },
    createSsoConfigError(state, { payload }) {
      const errors = {
        ...state.errors,
        createConfig: payload.error,
      };

      setState(state, {
        isCreatingConfig: false,
        errors,
      });
    },
    deleteSsoConfigRequest(state) {
      const errors = {
        ...state.errors,
        deleteConfig: null,
      };

      setState(state, {
        isDeletingConfig: true,
        errors,
      });
    },
    deleteSsoConfigSuccess(state) {
      setState(state, {
        isDeletingConfig: false,
        config: null,
      });
    },
    deleteSsoConfigError(state, { payload }) {
      const errors = {
        ...state.errors,
        deleteConfig: payload.error,
      };

      setState(state, {
        isDeletingConfig: false,
        errors,
      });
    },
    enableSsoConfigRequest(state) {
      const errors = {
        ...state.errors,
        enableConfig: null,
      };

      setState(state, {
        isEnablingConfig: true,
        errors,
      });
    },
    enableSsoConfigSuccess(state) {
      const config = {
        ...state.config,
        enabled: true,
      };

      setState(state, {
        isEnablingConfig: false,
        config,
      });
    },
    enableSsoConfigError(state, { payload }) {
      const errors = {
        ...state.errors,
        enableConfig: payload.error,
      };

      setState(state, {
        isEnablingConfig: false,
        errors,
      });
    },
  },
});

const { actions, reducer } = ssoSlice;

export const {
  fetchSsoConfigRequest,
  fetchSsoConfigSuccess,
  fetchSsoConfigError,
  createSsoConfigRequest,
  createSsoConfigSuccess,
  createSsoConfigError,
  deleteSsoConfigRequest,
  deleteSsoConfigSuccess,
  deleteSsoConfigError,
  enableSsoConfigRequest,
  enableSsoConfigSuccess,
  enableSsoConfigError,
} = actions;

export default reducer;
