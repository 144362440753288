import _minBy from 'lodash/minBy';
import _maxBy from 'lodash/maxBy';
import _get from 'lodash/get';
import _intersectionWith from 'lodash/intersectionWith';
import _isEmpty from 'lodash/isEmpty';
import { SEARCH_TYPES } from '../../constants/findMedicalCare';

const getEducationDisplayName = (educations = []) =>
  educations
    ?.reduce((acc, curr) => {
      const type = curr?.type || '';
      const name = curr?.education?.name || '';
      return [...acc, `${type} ${type ? '-' : ''} ${name}`.trim()];
    }, [])
    .join('\n\n');

const getSpecialtiesDisplayName = (specialties = []) => {
  if (_isEmpty(specialties)) {
    return null;
  }
  return specialties
    .reduce((acc, curr) => {
      const providerName = curr?.provider_name;
      return acc.includes(providerName) ? acc : [...acc, providerName];
    }, [])
    .join(', ');
};

const intersectInsurances = (userInsurances = [], insurances = []) => {
  // If either set of insurances is empty, there is no intersection.
  if (_isEmpty(userInsurances) || _isEmpty(insurances)) {
    return [];
  }

  return _intersectionWith(
    userInsurances,
    insurances,
    (userInsurance, insurance) => userInsurance.networkId === insurance.uuid,
  );
};

const getIsFacilityInNetwork = (userInsurance, providerInsurance) =>
  !_isEmpty(intersectInsurances(userInsurance, providerInsurance));

const getIsProviderInNetwork = (userInsurance, providerInsurance) =>
  !_isEmpty(intersectInsurances(userInsurance, providerInsurance));

const getProviderDisplayName = ({
  degrees,
  first_name: firstName,
  last_name: lastName,
}) => {
  const suffix = degrees && degrees.length ? `, ${degrees.join(', ')}` : '';
  return `${firstName} ${lastName}${suffix}`;
};

const normalizeParameters = ({
  // TODO: probably won't need this
  total_count: totalCount,
  page_size: pageSize,
  address,
  distance,
  page,
}) => ({
  totalCount,
  address,
  distance,
  page,
  pageSize,
});

const getLocation = provider => {
  // Distance is included only when we supply user location to search
  const hasDistanceParam = !!_get(provider, ['locations', 0, 'distance']);

  return hasDistanceParam
    ? _minBy(provider.locations, 'distance')
    : _maxBy(provider.locations, 'confidence');
};

const getLocationDetails = provider => {
  const location = getLocation(provider);
  const { city, state, street, zip } = location?.address_details || {};
  return {
    city,
    location: location?.address,
    state,
    street,
    zip,
  };
};

const normalizeLocation = (
  location,
  planNetworkExternalMaps,
  savedResultsIds,
) => {
  const {
    uuid,
    name,
    confidence,
    address,
    distance,
    latitude,
    longitude,
    phone_numbers: phoneNumbers,
  } = location;
  const enrollments = intersectInsurances(
    planNetworkExternalMaps,
    location?.insurances,
  );
  const isSaved = savedResultsIds?.includes(uuid);
  const locationDetails = {
    ...location,
    uuid,
    confidence,
    address,
    distance,
    latitude,
    longitude,
    id: uuid,
    phone_numbers: phoneNumbers?.map(phoneNumberObj => ({
      ...phoneNumberObj,
      details: phoneNumberObj.detail,
    })),
  };

  return {
    ...location,
    isSaved,
    name,
    uuid,
    enrollments,
    type: SEARCH_TYPES.LOCATION,
    isInNetwork: !_isEmpty(enrollments),
    lat: latitude,
    lng: longitude,
    location: locationDetails,
    locations: [locationDetails],
    id: location.uuid,
    displayName: location.name,
    displaySpecialty: location.location_types?.join(', '),
  };
};

const normalizeProvider = (
  provider = {},
  userInsurance = {},
  savedProvidersIds = [],
) => {
  const location = getLocation(provider);
  const enrollments = intersectInsurances(userInsurance, provider?.insurances);
  const isSaved = savedProvidersIds?.includes(provider.npi);

  return {
    ...provider,
    isSaved,
    enrollments,
    id: provider.npi,
    type: SEARCH_TYPES.PROVIDER,
    isInNetwork: !_isEmpty(enrollments),
    displayName: getProviderDisplayName(provider),
    displaySpecialty: getSpecialtiesDisplayName(provider.specialties),
    displayEducationName: getEducationDisplayName(provider.educations),
    ...getLocationDetails(provider),
    location,
    lat: location.latitude,
    lng: location.longitude,
  };
};

const normalizeLocationSearchResults = (
  results = [],
  planNetworkExternalMaps = {},
  savedResultsIds = [],
) => {
  if (_isEmpty(results)) {
    return [];
  }
  return results.map(result =>
    normalizeLocation(result, planNetworkExternalMaps, savedResultsIds),
  );
};

const normalizeProviderSearchResults = (
  results = [],
  planNetworkExternalMaps = {},
  savedResultsIds = [],
) => {
  if (_isEmpty(results)) {
    return [];
  }
  return results.map(result =>
    normalizeProvider(result, planNetworkExternalMaps, savedResultsIds),
  );
};

const normalizeSearchResults = (
  type,
  results,
  planNetworkExternalMaps,
  savedResultsIds,
) => {
  switch (type) {
    case SEARCH_TYPES.LOCATION:
    case SEARCH_TYPES.LOCATION_TYPE:
      return normalizeLocationSearchResults(
        results,
        planNetworkExternalMaps,
        savedResultsIds,
      );
    case SEARCH_TYPES.PROVIDER:
    case SEARCH_TYPES.SPECIALTY:
    default:
      return normalizeProviderSearchResults(
        results,
        planNetworkExternalMaps,
        savedResultsIds,
      );
  }
};

const normalizeResultsPagination = ({
  page,
  page_size: pageSize,
  total_count: totalCount,
}) => ({
  page,
  pageSize,
  totalCount,
});

export {
  getEducationDisplayName,
  getIsFacilityInNetwork,
  getIsProviderInNetwork,
  getProviderDisplayName,
  intersectInsurances,
  normalizeParameters,
  normalizeLocation,
  normalizeLocationSearchResults,
  normalizeProvider,
  normalizeProviderSearchResults,
  normalizeSearchResults,
  normalizeResultsPagination,
};
