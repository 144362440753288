import { put, takeEvery, all } from 'redux-saga/effects';
import * as api from '../../api/companyAccesses';
import {
  checkCompanyAccessRequest,
  checkCompanyAccessSuccess,
  checkCompanyAccessError,
  getCompanyAccessRequestSuccess,
  setUserType,
} from './index';

export function* checkCompanyAccess() {
  try {
    const [companyAccesses, userType] = yield all([
      api.fetchCompanyAccesses(),
      api.fetchUserType(),
    ]);

    const company =
      companyAccesses &&
      companyAccesses.companies &&
      companyAccesses.companies[0];

    const { type, isImpersonationSession } = userType;
    const isSuperAdmin = type === 'SuperAdministrator';

    yield put(
      getCompanyAccessRequestSuccess({
        allCompanies: companyAccesses.companies,
        currentCompanySubdomain: company?.subdomain,
      }),
    );

    yield put(setUserType({ isSuperAdmin, isImpersonationSession }));

    yield put(checkCompanyAccessSuccess(!!companyAccesses?.companies.length));
  } catch (error) {
    yield put(checkCompanyAccessError(error.message));
  }
}

export function* checkCompanyAccessWatcher() {
  yield takeEvery(checkCompanyAccessRequest, checkCompanyAccess);
}
