import { createSlice } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';

import initialState from './initialState';
import { setState } from '../../helpers/store';

const findADoctorSlice = createSlice({
  name: 'findADoctor',
  initialState,
  reducers: {
    fetchProviderRequest(state) {
      setState(state, {
        isFetchingProviderResults: true,
        errorFetchingProvider: null,
        shouldDeleteProvider: null,
      });
    },
    fetchProviderSuccess(
      state,
      {
        payload: { appointmentProvider },
      },
    ) {
      setState(state, {
        appointmentProvider,
        isFetchingProviderResults: false,
      });
    },
    fetchProviderError(
      state,
      {
        payload: { errorFetchingProvider, shouldDeleteProvider },
      },
    ) {
      setState(state, {
        shouldDeleteProvider,
        errorFetchingProvider,
        isFetchingProviderResults: false,
      });
    },
    searchProvidersResetFields(state) {
      setState(state, initialState);
    },
    searchProvidersSearchTermChange(
      state,
      {
        payload: { providerSearchTerm },
      },
    ) {
      setState(state, { providerSearchTerm });
    },
    searchSpecialtySearchTermChange(
      state,
      {
        payload: { specialtySearchTerm },
      },
    ) {
      setState(state, { specialtySearchTerm });
    },
    searchSpecialtiesLocationChange(
      state,
      {
        payload: { appointmentLocation },
      },
    ) {
      setState(state, { appointmentLocation });
    },
    searchSpecialtiesSpecialtyChange(
      state,
      {
        payload: { appointmentSpecialty },
      },
    ) {
      setState(state, { appointmentSpecialty });
    },
    fetchSavedProvidersRequest(state) {
      setState(state, {
        errorFetchingSavedProviders: null,
        isFetchingSavedProviders: true,
      });
    },
    fetchSavedProvidersSuccess(
      state,
      {
        payload: { savedProviders },
      },
    ) {
      setState(state, {
        savedProviders: _keyBy(savedProviders, 'id'),
        isFetchingSavedProviders: false,
        hasFetchedSavedProviders: true,
      });
    },
    fetchSavedProvidersError(
      state,
      {
        payload: { errorFetchingSavedProviders },
      },
    ) {
      setState(state, {
        errorFetchingSavedProviders,
        isFetchingSavedProviders: false,
      });
    },
    saveProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    saveProviderSuccess(
      state,
      {
        payload: { provider },
      },
    ) {
      setState(state, {
        savedProviders: {
          [provider.id]: provider,
        },
        isSettingSavedProvider: false,
      });
    },
    saveProviderError(
      state,
      {
        payload: { errorSavingProvider },
      },
    ) {
      setState(state, {
        errorSavingProvider,
        isSettingSavedProvider: false,
      });
    },
    deleteProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    deleteProviderSuccess(
      state,
      {
        payload: { id },
      },
    ) {
      setState(state, {
        savedProviders: _omit(state.savedProviders, id),
        isSettingSavedProvider: false,
      });
    },
    deleteProviderError(
      state,
      {
        payload: { errorDeletingSavedProvider },
      },
    ) {
      setState(state, {
        errorDeletingSavedProvider,
        isSettingSavedProvider: false,
      });
    },
    setInfoWindowContent(
      state,
      {
        payload: { infoWindowContent },
      },
    ) {
      setState(state, {
        infoWindowContent,
      });
    },
    setInfoWindowPaginationPage(
      state,
      {
        payload: { infoWindowPaginationPage },
      },
    ) {
      setState(state, {
        infoWindowPaginationPage,
      });
    },
    setActiveMarker(
      state,
      {
        payload: { activeMarkerCoordinates },
      },
    ) {
      setState(state, {
        activeMarkerCoordinates,
      });
    },
  },
});

const { actions, reducer } = findADoctorSlice;

export const {
  fetchProviderRequest,
  fetchProviderSuccess,
  fetchProviderError,
  searchProvidersResetFields,
  searchSpecialtySearchTermChange,
  searchSpecialtiesLocationChange,
  searchSpecialtiesSpecialtyChange,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  fetchSavedProvidersError,
  saveProviderRequest,
  saveProviderSuccess,
  saveProviderError,
  deleteProviderRequest,
  deleteProviderSuccess,
  deleteProviderError,
  setInfoWindowContent,
  setInfoWindowPaginationPage,
  setActiveMarker,
  searchProvidersSearchTermChange,
  searchProvidersRequest,
} = actions;

export default reducer;
