export const isStaging = (hostName = '') => {
  return hostName.toLowerCase().search(`stg.bennie.com`) >= 0;
};

export const isDev = (hostName = '') => {
  return hostName.toLowerCase().search(`dev.bennie.com`) >= 0;
};

export const isProd = (hostName = '') => {
  return (
    !isStaging(hostName) &&
    !isDev(hostName) &&
    hostName.toLowerCase().search(`bennie.com`) >= 0
  );
};

export const getEnvironmentName = (hostName = '') => {
  if (isProd(hostName)) {
    return 'Production';
  }
  if (isStaging(hostName)) {
    return 'Staging';
  }

  if (isDev(hostName)) {
    return 'Dev';
  }

  return 'Local';
};

export const getHost = (hostName = '') => {
  const env = getEnvironmentName(hostName);

  if (env === 'Production') {
    return 'bennie.com';
  }

  if (env === 'Staging') {
    return 'stg.bennie.com';
  }

  if (env === 'Dev') {
    return 'dev.bennie.com';
  }

  return 'bennie.local';
};
