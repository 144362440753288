/* eslint-disable camelcase */
import qs from 'qs';
import _isNil from 'lodash/isNil';
import _snakeCase from 'lodash/snakeCase';
import {
  DEFAULT_PROXIMITY,
  SEARCH_TYPES,
} from '../../constants/findMedicalCare';
import I18n from '../../i18n';
import { ribbonHealthClient as client } from '../../helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '../../helpers/requests/bennieFetch';
import { bennieApi } from '../../store/bennieApi';

const stringifyParams = params => qs.stringify(params);
const PRIMARY_CARE_PROVIDER = 'Primary Care Provider';
const SHOULD_SHOW_UI_ERROR_STATUS_CODES = [429, 500, 400];

const requiredProvidersFields = [
  'npi',
  'first_name',
  'middle_name',
  'last_name',
  'degrees',
  'distance',
  'ratings_count',
  'ratings_avg',
  'insurances.uuid',
  'specialties.uuid',
  'specialties.provider_name',
  'locations.uuid',
  'locations.address',
  'locations.distance',
  'locations.latitude',
  'locations.longitude',
  'locations.confidence',
].join(',');

const requiredLocationsFields = [
  'uuid',
  'name',
  'address',
  'confidence',
  'latitude',
  'longitude',
  'distance',
  'location_types',
  'insurances.uuid',
].join(',');

const normalizeParams = params => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (_isNil(value)) {
      return acc;
    }

    return {
      ...acc,
      [_snakeCase(key)]: value,
    };
  }, {});
};

const getInsuranceIdsParam = (type, insuranceIds) => {
  if (!insuranceIds) {
    return {};
  }

  switch (type) {
    case SEARCH_TYPES.PROVIDER:
    case SEARCH_TYPES.SPECIALTY:
      return { location_insurance_ids: insuranceIds };
    case SEARCH_TYPES.LOCATION:
    case SEARCH_TYPES.LOCATION_TYPE:
      return { insurance_ids: insuranceIds };
    default:
      return {};
  }
};

const getLocationParam = location => {
  if (!location) {
    return {};
  }

  if (typeof location === 'string') {
    return { location };
  }

  return { address: location.displayName };
};

const getFilterParams = params => {
  const { language, gender, minRating } = params;

  return {
    language,
    gender,
    minRating: minRating ? minRating / 10 : null,
  };
};

const getParamsForSearchType = (type, params) => {
  const { location, insuranceIds, page } = params;

  let paramsForType = {
    page: page || 1,
    page_size: 20,
    ...getInsuranceIdsParam(type, insuranceIds),
    ...getLocationParam(location),
  };

  switch (type) {
    case SEARCH_TYPES.PROVIDER:
      paramsForType.distance = DEFAULT_PROXIMITY;
      paramsForType.fields = requiredProvidersFields;
      paramsForType.location_within_distance = true;
      paramsForType.min_location_confidence = 3;
      paramsForType.name = params.value;
      paramsForType = {
        ...paramsForType,
        ...getFilterParams(params),
      };
      break;
    case SEARCH_TYPES.SPECIALTY:
      paramsForType.distance = DEFAULT_PROXIMITY;
      paramsForType.fields = requiredProvidersFields;
      paramsForType.location_within_distance = true;
      paramsForType.min_location_confidence = 3;
      paramsForType = {
        ...paramsForType,
        ...getFilterParams(params),
        ...(params.value === PRIMARY_CARE_PROVIDER
          ? { specialty: params.value }
          : { specialty_ids: params.id }),
      };
      break;
    case SEARCH_TYPES.LOCATION:
      paramsForType.fields = requiredLocationsFields;
      paramsForType.min_confidence = 3;
      paramsForType.name = params.value;
      break;
    case SEARCH_TYPES.LOCATION_TYPE:
      paramsForType.fields = requiredLocationsFields;
      paramsForType.min_confidence = 3;
      paramsForType.location_types = params.value;
      break;
    default:
      break;
  }

  return paramsForType;
};

const getEndpointForSearchType = type => {
  switch (type) {
    case SEARCH_TYPES.PROVIDER:
    case SEARCH_TYPES.SPECIALTY:
      return 'providers';
    case SEARCH_TYPES.LOCATION:
    case SEARCH_TYPES.LOCATION_TYPE:
      return 'locations';
    default:
      return null;
  }
};

// Handles search for both providers and facilities
export const search = async ({ type, ...params }) => {
  const endpoint = getEndpointForSearchType(type);
  const normalizedParams = normalizeParams(
    getParamsForSearchType(type, params),
  );
  const queryStringParams = stringifyParams(normalizedParams);

  return client.get(`${endpoint}?${queryStringParams}`).then(response => {
    if (
      SHOULD_SHOW_UI_ERROR_STATUS_CODES.includes(+response.status) ||
      responseFailed(response.status)
    ) {
      throw new Error(I18n.t('findMedicalCare.error.errorFetching'));
    }

    return getResponseData(response);
  });
};

export const searchAutoSuggest = async ({
  address,
  insurance_ids,
  location_insurance_ids,
  location,
  search: searchTerm,
}) => {
  const queryStringParams = stringifyParams({
    search: searchTerm, // Required
    ...(address && { address }),
    ...(insurance_ids && { insurance_ids }),
    ...(location && { location }),
    ...(location_insurance_ids && { location_insurance_ids }),
  });

  return client.get(`auto-suggest?${queryStringParams}`).then(response => {
    if (
      SHOULD_SHOW_UI_ERROR_STATUS_CODES.includes(+response.status) ||
      responseFailed(response.status)
    ) {
      throw new Error(
        `${I18n.t(`findMedicalCare.error.errorFetchingProviders`)}`,
      );
    }
    return getResponseData(response);
  });
};

export const fetchItem = async ({ id, type }) => {
  const endpoint = type === SEARCH_TYPES.PROVIDER ? 'providers' : 'locations';
  const requiredFields =
    type === SEARCH_TYPES.PROVIDER
      ? requiredProvidersFields
      : requiredLocationsFields;

  return client
    .get(`${endpoint}/${id}?fields=${requiredFields}`)
    .then(response => {
      if (responseFailed(response.status)) {
        throw new Error(I18n.t('findMedicalCare.error.errorFetching'));
      }
      return getResponseData(response);
    });
};

const findCareApi = bennieApi.injectEndpoints({
  endpoints: build => ({
    getProvider: build.query({
      query: ({ id }) => ({
        url: `api/find-a-doctor/v1/providers/${id}`,
        params: {
          fields: requiredProvidersFields,
        },
      }),
    }),
    getLocation: build.query({
      query: ({ id }) => ({
        url: `api/find-a-doctor/v1/locations/${id}`,
        params: {
          fields: requiredLocationsFields,
        },
      }),
    }),
  }),
});

export const { useGetProviderQuery, useGetLocationQuery } = findCareApi;
