import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithReAuth } from '../helpers/requests/createBaseQueryWithReAuth';

const baseQuery = createBaseQueryWithReAuth(
  `${process.env.BENNIE_API_GATEWAY_BASE_URL}/`,
);
export const bennieApi = createApi({
  reducerPath: 'bennieApi',
  baseQuery,
  endpoints: () => ({}),
});
