import { put, takeLatest } from 'redux-saga/effects';
import * as api from '../../api/sessions';
import { saveSessionError, saveSessionRequest, saveSessionSuccess } from '.';

function* saveSession() {
  try {
    const response = yield api.saveSession();
    yield put(saveSessionSuccess(response));
  } catch (error) {
    yield put(saveSessionError(error.message || 'Could not save provider'));
  }
}

export function* saveSessionWatcher() {
  yield takeLatest(saveSessionRequest, saveSession);
}
