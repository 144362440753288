import { coreClient as client } from '../../helpers/requests/client';
import { responseDataAdapter } from '../../helpers/requests/bennieFetch';

export const fetchDeductibleDetails = () =>
  client
    .get(`/v1/profile/deductibledetails`)
    .then(response => responseDataAdapter(response));

export const fetchDeductibleDetailsCache = () =>
  client
    .get(`/v1/profile/deductibledetails/cache`)
    .then(response => responseDataAdapter(response));

export const updateDeductibleDetailsCache = ({ requestId }) => {
  return client
    .post(`/v1/profile/deductibledetails/cache`, { requestId })
    .then(response => responseDataAdapter(response));
};
