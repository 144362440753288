import { coreClient as client } from '../../helpers/requests/client';
import { responseDataAdapter } from '../../helpers/requests/bennieFetch';

export const fetchProfile = () =>
  client.get(`/v1/profile`).then(data => responseDataAdapter(data));

export const fetchProfileApplicationStatus = () =>
  client
    .get(`/v1/profile/applicationstatus`)
    .then(data => responseDataAdapter(data));
