const CLIENT_ID = process.env.OKTA_CLIENT_ID;
const ISSUER = process.env.OKTA_ISSUER;
const REDIRECT_URI = `${window.location.origin}/implicit/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: false,
    tokenManager: {
      storageKey: 'bennie-web-token-storage',
    },
  },
};
