export const DEFAULT_PROXIMITY = 100;

export const SEARCH_TYPES = {
  LOCATION: 'location',
  LOCATION_TYPE: 'locationType',
  PROVIDER: 'provider',
  SPECIALTY: 'specialty',
};

// TODO: This will probably live somewhere near the component that uses it after refactoring search page
export const FILTERS = {
  GENDER: {
    defaultValue: null,
    name: 'gender',
    options: {
      MALE: 'm',
      FEMALE: 'f',
    },
  },
  LANGUAGE: {
    defaultValue: null,
    name: 'language',
    options: {
      SPANISH: 'Spanish',
      FRENCH: 'French',
      HINDI: 'Hindi',
      RUSSIAN: 'Russian',
      ARABIC: 'Arabic',
      CHINESE: 'Chinese',
    },
  },
  IN_NETWORK: {
    defaultValue: null,
    name: 'insuranceIds',
  },
  RATINGS: {
    defaultValue: null,
    name: 'minRating',
    options: {
      MIN: 75,
      MAX: 100,
      STEP: 1,
    },
  },
  SEARCH_CONTEXT: {
    defaultValue: SEARCH_TYPES.PROVIDER,
    name: 'type',
    options: {
      PROVIDER: SEARCH_TYPES.PROVIDER,
      LOCATION: SEARCH_TYPES.LOCATION,
    },
  },
};
