import { coreClient as client } from '../../helpers/requests/client';
import {
  getResponseData,
  responseDataAdapter,
  responseSuccess,
} from '../../helpers/requests/bennieFetch';

export const inviteDependent = async (email, dependentId) => {
  return client
    .post(`/v1/users/dependents`, {
      email,
      dependentId,
    })
    .then(response => getResponseData(response))
    .catch(error => {
      const { errors } = error.response.data;

      return { errors: errors || 'Could not invite dependent' };
    });
};

export const updateDependentEmail = async (email, dependentId) => {
  return client
    .put(`/v1/users/dependents`, {
      email,
      dependentId,
    })
    .then(response => {
      const { status } = response;

      if (responseSuccess(status)) {
        return 'success';
      }

      return getResponseData(response);
    })
    .catch(error => {
      const { errors } = error.response.data;
      return { errors: errors || 'Could not update dependent email' };
    });
};

export const resendInviteRequest = async id => {
  return client
    .post(`/v1/users/dependents/${id}/resendactivationemail`)
    .then(response => {
      const { status } = response;

      if (responseSuccess(status)) {
        return 'success';
      }

      return getResponseData(response);
    })
    .catch(error => {
      const { errors } = error.response.data;

      return { errors: errors || 'Could not resend invite' };
    });
};

export const getDependentsList = () =>
  client
    .get(`/v1/users/dependents`)
    .then(response => responseDataAdapter(response));
