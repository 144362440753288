import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSavingSession: false,
  first: null,
  last: null,
};

const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    saveSessionRequest: state => {
      state.isSavingSession = true;
    },
    saveSessionSuccess: (state, { payload }) => {
      state.isSavingSession = false;
      state.first = payload.first;
      state.last = payload.last;
    },
    saveSessionError: (state, { payload }) => {
      state.isSavingSession = false;
      state.error = payload.error;
    },
  },
});

const { actions, reducer } = sessionsSlice;

export const {
  saveSessionRequest,
  saveSessionSuccess,
  saveSessionError,
} = actions;

export default reducer;
