import { all } from 'redux-saga/effects';
import {
  messages,
  claims,
  marketplace,
  admin,
  perks,
  postLoginExperience,
  profile,
  blogPosts,
} from '@benniehealth/core-js';
import { insights } from '@benniehealth/insights-js';
import { fetchInitialDataWatcher } from '../appState/sagas';
import {
  deleteProviderWatcher,
  fetchSavedProvidersWatcher,
  getProviderWatcher,
  saveProviderWatcher,
} from '../findADoctor/sagas';

import {
  fetchSavedFacilitiesWatcher,
  saveFacilityWatcher,
  fetchItemWatcher,
  searchWatcher,
  deleteFacilityWatcher,
  deleteProviderWatcher as deleteProviderWatcherMC,
  saveProviderWatcher as saveProviderWatcherMC,
  fetchSavedProvidersWatcher as fetchSavedProvidersWatcherMC,
} from '../findMedicalCare/sagas';

import { saveSessionWatcher } from '../sessions/sagas';

import { checkCompanyAccessWatcher } from '../companyAccesses/sagas';

import { logoutWatcher } from '../auth/sagas';

import { checkLocationPermissionsWatcher } from '../location/sagas';

import { fetchBlogPosts } from '../../api/contentful';

import { toggleAskBennieModalWatcher } from '../modal/sagas';

import { adminRequestAvailableCompanyExportsWatcher } from '../admin/exports/sagas';
import { adminRequestUsersWatcher } from '../admin/users/sagas';
import {
  adminFetchSSOConfigWatcher,
  adminCreateSSOConfigWatcher,
  adminDeleteSSOConfigWatcher,
  adminEnableSSOConfigWatcher,
} from '../admin/sso/sagas';
import { fetchDocumentsWatcher } from '../enrollmentDocuments/sagas';
import { fetchClientAttributesWatcher } from '../clientAttributes/sagas';
import { getTelehealthConfigurationWatcher } from '../telehealth/sagas';
import * as messagesApi from '../../api/messages';
import * as claimsApi from '../../api/deductibleDetails';
import * as marketplaceApi from '../../api/marketplace';
import {
  fetchAdminPerks,
  fetchAdminArchivedPerks,
  fetchAdminPerk,
} from '../../api/admin/perks';
import { fetchPerks } from '../../api/perks';
import {
  completeAppTutorial,
  fetchRestartAppTutorial,
} from '../../api/postLoginExperience';
import * as profileApi from '../../api/profile';
import * as dependentsApi from '../../api/dependents';
import { fetchInsights } from '../../api/insights';

export default function* rootSaga() {
  yield all([
    fetchInitialDataWatcher(),
    toggleAskBennieModalWatcher(),
    fetchClientAttributesWatcher(),
    profile.sagas.fetchProfileWatcher(profileApi.fetchProfile),
    profile.sagas.fetchApplicationStatusWatcher(
      profileApi.fetchProfileApplicationStatus,
    ),
    searchWatcher(),
    deleteProviderWatcher(),
    fetchSavedProvidersWatcher(),
    getProviderWatcher(),
    saveProviderWatcher(),
    fetchSavedFacilitiesWatcher(),
    fetchItemWatcher(),
    saveFacilityWatcher(),
    deleteFacilityWatcher(),
    deleteProviderWatcherMC(),
    saveProviderWatcherMC(),
    fetchSavedProvidersWatcherMC(),
    saveSessionWatcher(),
    checkLocationPermissionsWatcher(),
    adminRequestAvailableCompanyExportsWatcher(),
    adminRequestUsersWatcher(),
    adminFetchSSOConfigWatcher(),
    adminCreateSSOConfigWatcher(),
    adminDeleteSSOConfigWatcher(),
    adminEnableSSOConfigWatcher(),
    fetchDocumentsWatcher(),
    checkCompanyAccessWatcher(),
    getTelehealthConfigurationWatcher(),
    logoutWatcher(),
    profile.sagas.fetchDependentsWatcher(dependentsApi.getDependentsList),
    messages.sagas.fetchTicketWatcher(messagesApi.fetchTicket),
    messages.sagas.fetchTicketsWatcher(messagesApi.fetchOpenTickets),
    messages.sagas.fetchResolvedTicketsWatcher(
      messagesApi.fetchResolvedTickets,
    ),
    messages.sagas.fetchAgentsWatcher(messagesApi.fetchAgents),
    messages.sagas.fetchTicketFieldsWatcher(messagesApi.fetchTicketFields),
    messages.sagas.createTicketWatcher(messagesApi.createTicket),
    messages.sagas.updateTicketWatcher(messagesApi.updateTicket),
    messages.sagas.fetchCommentsWatcher(messagesApi.fetchComments),
    messages.sagas.fetchZendeskUserWatcher(messagesApi.fetchZendeskUser),
    messages.sagas.pollTicketUpdatesWatcher(),
    claims.sagas.fetchDeductibleDetailsWatcher(
      claimsApi.fetchDeductibleDetails,
      claimsApi.fetchDeductibleDetailsCache,
      claimsApi.updateDeductibleDetailsCache,
    ),
    marketplace.sagas.fetchEmployeeMarketplaceWatcher(
      marketplaceApi.fetchEmployeeMarketplace,
    ),
    marketplace.sagas.fetchEmployerMarketplaceWatcher(
      marketplaceApi.fetchEmployerMarketplace,
    ),
    admin.perks.sagas.fetchAdminPerksWatcher(fetchAdminPerks),
    admin.perks.sagas.fetchAdminArchivedPerksWatcher(fetchAdminArchivedPerks),
    admin.perks.sagas.fetchAdminPerkWatcher(fetchAdminPerk),
    perks.sagas.fetchPerksWatcher(fetchPerks),
    postLoginExperience.sagas.fetchRestartAppTutorialWatcher(
      fetchRestartAppTutorial,
    ),
    postLoginExperience.sagas.completeAppTutorialWatcher(completeAppTutorial),
    blogPosts.sagas.fetchCuratedBlogPostsWatcher(fetchBlogPosts),
    insights.sagas.insightsRequestWatcher(fetchInsights),
  ]);
}
