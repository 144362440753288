import { createSlice } from '@reduxjs/toolkit';

import { setState } from '../../../helpers/store';

const exportsSlice = createSlice({
  name: 'exports',
  initialState: {
    isFetching: false,
    isFetched: false,
    error: null,
    companyId: null,
    companyLevelExports: null,
    allSubsidiaries: null,
    subsidiaryId: null,
  },
  reducers: {
    requestAvailableCompanyExports(state) {
      state.isFetching = true;
    },
    companyExportsLoadSuccess(
      state,
      {
        payload: {
          companyId,
          companyLevelExports,
          subsidiaries: allSubsidiaries,
        },
      },
    ) {
      setState(state, {
        companyId,
        companyLevelExports,
        allSubsidiaries,
        isFetching: false,
        isFetched: true,
      });
    },
    companyExportsLoadError(state, { payload: error }) {
      setState(state, {
        error,
        isFetching: false,
      });
    },
    /* NOTE looks like this is not called anywhere but subsidiaryId is used in
     app/pages/export-data/index.js line 287 and always will be null */
    changeSubsidiaryId(
      state,
      {
        payload: { subsidiaryId },
      },
    ) {
      state.subsidiaryId = subsidiaryId;
    },
  },
});

const { actions, reducer } = exportsSlice;

export const {
  requestAvailableCompanyExports,
  companyExportsLoadSuccess,
  companyExportsLoadError,
  changeSubsidiaryId,
} = actions;

export default reducer;
