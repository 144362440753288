import { combineReducers } from 'redux';
import { admin } from '@benniehealth/core-js';

import exports from './exports';
import sso from './sso';
import users from './users';

export default combineReducers({
  exports,
  sso,
  users,
  perks: admin.perks.slice,
});
