/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const enrollmentDocumentsSlice = createSlice({
  name: 'enrollmentDocuments',
  initialState: {
    documents: [],
    hasLoadedDocuments: false,
    isFetchingDocuments: true,
  },
  reducers: {
    fetchDocumentsRequest: state => {
      state.isFetchingDocuments = true;
    },
    fetchDocumentsSuccess: (state, { payload }) => {
      state.isFetchingDocuments = false;
      state.hasLoadedDocuments = true;
      state.documents = payload;
    },
    fetchDocumentsError: (state, { payload }) => {
      state.isFetchingDocuments = false;
      state.error = payload;
    },
  },
});

const { actions, reducer } = enrollmentDocumentsSlice;

export const {
  fetchDocumentsRequest,
  fetchDocumentsSuccess,
  fetchDocumentsError,
} = actions;

export default reducer;
