import { createSlice, nanoid } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    notifications: [],
  },
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }) => {
        state.notifications.push({
          key: payload.key,
          ...payload.notification,
        });
      },
      prepare: notification => ({
        payload: {
          notification: {
            ...notification,
            key: notification.options?.key ?? nanoid(),
          },
        },
      }),
    },
    closeSnackbar: {
      reducer: (state, { payload }) => {
        state.notifications = state.notifications.map(notification =>
          !payload.key || notification.key === payload.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        );
      },
      prepare: key => ({
        payload: {
          key,
        },
      }),
    },
    removeSnackbar: {
      reducer: (state, { payload }) => {
        state.notifications = state.notifications.filter(
          notification => notification.key !== payload.key,
        );
      },
      prepare: key => ({
        payload: {
          key,
        },
      }),
    },
  },
});

const { actions, reducer } = snackbarSlice;

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = actions;

export default reducer;
