/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const clientAttributesSlice = createSlice({
  name: 'clientAttributes',
  initialState: {
    isFetching: false,
  },
  reducers: {
    fetchClientAttributesRequest: state => {
      state.isFetching = true;
    },
    fetchClientAttributesSuccess: (state, { payload }) => {
      state.isFetching = false;
      state.attributes = payload;
    },
    fetchClientAttributesError: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

const { actions, reducer } = clientAttributesSlice;

export const {
  fetchClientAttributesRequest,
  fetchClientAttributesSuccess,
  fetchClientAttributesError,
} = actions;

export default reducer;
