import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';

import { ACCESS_LEVEL_OPTIONS } from '../../../constants/admin/users';
import { setState } from '../../../helpers/store';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isFetching: false,
    isFetched: false,
    usersList: null,
    meta: {
      accessLevelOptions: ACCESS_LEVEL_OPTIONS,
    },
    pagination: {},
    usersUpdating: {},
    searchRequest: null,
    fetchError: false,
    error: null,
    isFetchingMFAUsersList: false,
    hasFetchedMFAUsersList: false,
    MFAUsersList: null,
    errorFetchingMFAUsersList: null,
    MFAPagination: {},
  },
  reducers: {
    fetchUsersRequest(state) {
      setState(state, {
        isFetching: true,
        isFetched: false,
      });
    },
    fetchUsersSuccess(
      state,
      {
        payload: { response, params },
      },
    ) {
      setState(state, {
        isFetching: false,
        isFetched: true,
        usersList: response.items
          ? response.items.map(item => {
              return {
                ...item,
                accessLevel: state.meta.accessLevelOptions.find(
                  option => option.id === item.accessLevel,
                ),
              };
            })
          : [],
        pagination: {
          ...state.pagination,
          totalSize: response.totalSize,
          pageNumber: _get(response, `pagingRequest.pageNumber`),
          itemsOnPage: _get(response, `pagingRequest.itemsOnPage`),
        },
        searchRequest: _get(params, `name`, null),
      });
    },
    fetchUsersError(
      state,
      {
        payload: { error },
      },
    ) {
      setState(state, {
        isFetching: false,
        isFetched: true,
        fetchError: true,
        error,
      });
    },
    clearErrors(state) {
      state.error = null;
    },
  },
});

const { actions, reducer } = usersSlice;

export const {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersError,
  clearErrors,
} = actions;

export default reducer;
