import Slide from '@mui/material/Slide';

export const snackbarOptions = {
  maxSnack: 5,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  preventDuplicate: true,
  TransitionComponent: Slide,
};
