import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const PageImportFallback = () => {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default PageImportFallback;
