import I18n from '../../i18n';

export const defaultCardList = [
  {
    id: 1,
    telehealthProvider: 'Teladoc',
    telehealthLink: I18n.t(
      `telehealthScreen.telehealthProviders.Teladoc.telehealthLink`,
    ),
    description: I18n.t(
      `telehealthScreen.telehealthProviders.Teladoc.description`,
    ),
  },
  {
    id: 2,
    telehealthProvider: 'MdLive',
    telehealthLink: I18n.t(
      `telehealthScreen.telehealthProviders.MdLive.telehealthLink`,
    ),
    description: I18n.t(
      `telehealthScreen.telehealthProviders.MdLive.description`,
    ),
  },
  {
    id: 3,
    telehealthProvider: 'Amwell',
    telehealthLink: I18n.t(
      `telehealthScreen.telehealthProviders.Amwell.telehealthLink`,
    ),
    description: I18n.t(
      `telehealthScreen.telehealthProviders.Amwell.description`,
    ),
  },
  {
    id: 4,
    telehealthProvider: 'DoctorOnDemand',
    telehealthLink: I18n.t(
      `telehealthScreen.telehealthProviders.DoctorOnDemand.telehealthLink`,
    ),
    description: I18n.t(
      `telehealthScreen.telehealthProviders.DoctorOnDemand.description`,
    ),
  },
  {
    id: 5,
    telehealthProvider: 'LiveHealthOnline',
    telehealthLink: I18n.t(
      `telehealthScreen.telehealthProviders.LiveHealthOnline.telehealthLink`,
    ),
    description: I18n.t(
      `telehealthScreen.telehealthProviders.LiveHealthOnline.description`,
    ),
  },
];
