const initialState = {
  providerSearchTerm: '',
  appointmentProvider: null,
  isFetchingProviderResults: false,
  specialtySearchTerm: '',
  appointmentSpecialty: null,
  appointmentLocation: null,
  isFetchingSavedFacilities: false,
  isFetchingSavedProviders: false,
  isSettingSavedProvider: false,
  savedFacilities: [],
  savedProviders: [],
  hasFetchedSavedProviders: false,
  infoWindowContent: null,
  infoWindowPaginationPage: 1,
  activeMarkerCoordinates: null,
  errorFetchingSavedFacilities: null,
  errorFetchingSavedProviders: null,
  errorSavingFacility: null,
  errorSavingProvider: null,
  errorDeletingSavedProvider: null,
  errorFetchingProvider: null,
  shouldDeleteProvider: null,
};

export default initialState;
