/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialDataAlreadyFetched: false,
  isInitialDataLoading: false,
  isLdUserIdentified: false,
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    fetchInitialDataRequest: state => {
      state.isInitialDataLoading = true;
    },

    fetchInitialDataSuccess: state => {
      state.isInitialDataLoading = false;
      state.initialDataAlreadyFetched = true;
      state.isLdUserIdentified = true;
    },
    fetchInitialDataError: (state, { payload }) => {
      state.isInitialDataLoading = false;
      state.initialDataAlreadyFetched = true;
      state.error = payload.error;
    },
  },
});

const { actions, reducer } = appStateSlice;

export const {
  fetchInitialDataRequest,
  fetchInitialDataSuccess,
  fetchInitialDataError,
} = actions;

export default reducer;
