import axios from 'axios';
import OktaAuth from '@okta/okta-auth-js';
import _omit from 'lodash/omit';
import { captureException } from '@sentry/react';
import { AXIOS_CORE_BASIC_CONFIG, OMITTED_URLS } from '../../constants/axios';

import oktaConfig from '../../okta.config';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

const coreClient = axios.create(AXIOS_CORE_BASIC_CONFIG);

const insightsClient = axios.create({
  baseURL: `${process.env.BENNIE_API_GATEWAY_BASE_URL}/api/reporting/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const ribbonHealthClient = axios.create({
  baseURL: `${process.env.BENNIE_API_GATEWAY_BASE_URL}/api/find-a-doctor/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const requestInterceptor = config => {
  const accesToken = oktaAuth.getAccessToken();
  const omitHeaders = OMITTED_URLS.some(omittedUrl =>
    config.url.includes(omittedUrl),
  );

  if (omitHeaders) {
    return {
      ...config,
      // Excludes Authorization header for endpoints listed in the
      // OMITTED_URLS array
      headers: _omit(config.headers, 'Authorization'),
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accesToken}`,
    },
  };
};

const errorInterceptor = client => async err => {
  const { config, response } = err;
  if (!config || !config.retry || !response) {
    return Promise.reject(err);
  }

  const retryCodes = [401, 403, 404];

  if (config.retry && retryCodes.includes(response.status)) {
    const token = await oktaAuth.tokenManager
      .renew('accessToken')
      .catch(error => {
        return Promise.reject(error);
      });

    if (!token.accessToken) {
      return Promise.reject(Error('Could not refresh access token'));
    }

    config.retry = config.retry ? config.retry - 1 : 0;
    config.headers.Authorization = `Bearer ${token.accessToken}`;
    config.retries = config.retries || 1;

    return new Promise(resolve => {
      resolve(client({ ...config, headers: config.headers.toJSON() }));
    });
  }

  captureException(err);
  return Promise.reject(err);
};

[coreClient, insightsClient, ribbonHealthClient].forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(null, errorInterceptor(client));
});

export { coreClient, insightsClient, ribbonHealthClient };
