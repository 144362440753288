import { takeLatest } from 'redux-saga/effects';
import { setAskBennieModalVisibility } from './index';

function* toggleAskBennieModal(action) {
  const { navigate, location, isVisible } = action.payload;
  navigate(
    {
      pathname: location.pathname,
      hash: `${location.hash}`,
    },
    { state: { isAskBennieModalVisible: isVisible } },
  );
}

export function* toggleAskBennieModalWatcher() {
  yield takeLatest(setAskBennieModalVisibility, toggleAskBennieModal);
}
