import mixpanel from 'mixpanel-browser';
import { analytics } from '@benniehealth/core-js';

import { USER_ROLES } from '../../constants/user';

export const init = (...args) => mixpanel.init(...args);

// https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneldisable
export const disable = () => mixpanel.disable();

export const setProfile = (...args) => mixpanel.people.set(...args);

export const reset = () => mixpanel.reset();

export const identify = (...args) => mixpanel.identify(...args);

export const setGroup = (groupKey, groupId) =>
  mixpanel.set_group(groupKey, groupId);

export const track = (...args) => mixpanel.track(...args);

export const getAccessType = access => {
  let accessTypeAnalytics;

  if (access === USER_ROLES.HR_ADMIN) {
    accessTypeAnalytics = analytics.admin.SET_ACCESS_HR_ADMIN;
  }

  if (access === USER_ROLES.EMPLOYEE) {
    accessTypeAnalytics = analytics.admin.SET_ACCESS_EMPLOYEE;
  }

  if (access === USER_ROLES.SYSTEM_ADMIN) {
    accessTypeAnalytics = analytics.admin.SET_ACCESS_SYSTEM_ADMIN;
  }

  return accessTypeAnalytics;
};

export const registerOnce = (...args) => mixpanel.register_once(...args);
