const FIELD_SLUGS = {
  TOPIC: 'topic',
  SPECIALTY: 'specialty',
  DATE: 'date',
  LOCATION: 'location',
  PROXIMITY: 'proximity',
  TIME: 'time',
  REASON: 'reason',
  PROVIDER: 'provider',
  NEW_PATIENT: 'new-patient',
  PARENT_TICKET_ID: 'parent-ticket-id',
};

export default FIELD_SLUGS;
