import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

import { styled } from '@mui/material/styles';

import { snackbarOptions } from './config';

const PREFIX = 'Provider';

const classes = {
  variantSuccess: `${PREFIX}-variantSuccess`,
  variantError: `${PREFIX}-variantError`,
  variantInfo: `${PREFIX}-variantInfo`,
  variantWarning: `${PREFIX}-variantWarning`,
};

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  [`& .${classes.variantSuccess}`]: {
    flexWrap: `nowrap`,
    backgroundColor: theme.palette.success.main,
  },
  [`& .${classes.variantError}`]: {
    flexWrap: `nowrap`,
    backgroundColor: theme.palette.error.main,
  },
  [`& .${classes.variantInfo}`]: {
    flexWrap: `nowrap`,
  },
  [`& .${classes.variantWarning}`]: {
    flexWrap: `nowrap`,
    backgroundColor: theme.palette.warning.main,
  },
  '& .SnackbarItem-action': {
    marginLeft: 0,
    marginRight: 0,
    position: 'absolute',
    right: '10px',
    top: 0,
    bottom: 0,
  },
  '& .SnackbarItem-message': {
    paddingRight: '25px',
  },
}));

const Provider = ({ children }) => {
  return (
    <StyledSnackbarProvider {...snackbarOptions} classes={classes}>
      {children}
    </StyledSnackbarProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
