export const DEFAULT_PROXIMITY = 100;

export const SEARCH_BY = {
  SPECIALTY: 'Specialty',
  PROVIDER: 'Provider',
};

export const QUICK_SEARCH_SPECIALTIES_LIST = [
  {
    name: 'Primary Care',
    link: `/find-a-doctor/search?specialty=Primary%20Care%20Provider&specialty_id=0c949aa8-6729-41c2-9d3a-5bd4a6966363&distance=${DEFAULT_PROXIMITY}`,
  },
  {
    name: 'Obstetrics and Gynecology',
    link: `/find-a-doctor/search?specialty=Obstetrics%20and%20Gynecology&specialty_id=9c470211-e3ea-4963-9568-8d8e9b8f5c44&distance=${DEFAULT_PROXIMITY}`,
  },
  {
    name: 'Dermatologists',
    link: `/find-a-doctor/search?specialty=Dermatology&specialty_id=b9dc44e1-6add-41f8-8df4-a8ef9cea706a&distance=${DEFAULT_PROXIMITY}`,
  },
  {
    name: 'Chiropractors',
    link: `/find-a-doctor/search?specialty=Chiropractor&specialty_id=167797fb-c9e0-4222-a287-fe33e8dd4ed6&distance=${DEFAULT_PROXIMITY}`,
  },
  {
    name: 'Gastroenterologists',
    link: `/find-a-doctor/search?specialty=Gastroenterology&specialty_id=ca571ba5-da97-4c9a-8289-2aaac198d4b2&distance=${DEFAULT_PROXIMITY}`,
  },
  {
    name: 'Telehealth',
    link: `/telehealth`,
  },
];

export const FILTER_BAR = {
  IN_NETWORK: 'In-Network',
  LANGUAGE: 'Language',
  GENDER: 'Gender',
  RATINGS: 'Ratings',
};

export const PROVIDER_LANGUAGES = {
  SPANISH: 'Spanish',
  FRENCH: 'French',
  HINDI: 'Hindi',
  RUSSIAN: 'Russian',
  ARABIC: 'Arabic',
  CHINESE: 'Chinese',
};

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
};

export const RATINGS = {
  MIN: 75,
  MAX: 100,
  STEP: 1,
};
