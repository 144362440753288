const EMPLOYEE = 'Employee';
const HR_ADMIN = 'HrAdmin';
const DEPENDENT = 'Dependent';
const SYSTEM_ADMIN = 'SystemAdmin';

const NOT_INVITED = 'NotInvited';
const INVITE_PENDING = 'InvitePending';
const ACTIVE = 'Active';
const NA = 'NA';
const DEACTIVATED = 'Deactivated';

const ACTIVATE = 'Activate';
const DEACTIVATE = 'Deactivate';

const DOWNGRADE_KEY = 'downgrade';
const ACTIVATE_KEY = 'activate';
const DEACTIVATE_KEY = 'deactivate';
const RESET_MFA = 'resetMFA';

export const USER_ROLES = {
  EMPLOYEE,
  HR_ADMIN,
  DEPENDENT,
  SYSTEM_ADMIN,
};
export const INVITATION_STATUS = {
  ACTIVE,
  INVITE_PENDING,
  NOT_INVITED,
  NA,
  DEACTIVATED,
};

export const CONFIRM_KEYS = {
  DOWNGRADE_KEY,
  ACTIVATE_KEY,
  DEACTIVATE_KEY,
  RESET_MFA,
};

export const ACTIVATION = { ACTIVATE, DEACTIVATE };

export const ACCESS_LEVEL = {
  EMPLOYEE,
  HR_ADMIN,
  DEPENDENT,
  SYSTEM_ADMIN,
  NA,
};
