import { coreClient as client } from '../../helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '../../helpers/requests/bennieFetch';

export const fetchSavedFacilities = async () => {
  try {
    const response = await client.get(`/api/ui-services/facilities`, {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    });
    if (responseFailed(response.status)) {
      throw new Error(response.statusText || 'Error fetching saved facilities');
    }
    return getResponseData(response);
  } catch (e) {
    throw new Error(e);
  }
};

export const saveFacility = async facility => {
  const response = await client.post(
    `/api/ui-services/facilities`,
    { ...facility, name: facility.displayName },
    {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    },
  );

  if (responseFailed(response.status)) {
    throw new Error(response.statusText || 'Error saving facility');
  }

  return getResponseData(response);
};

export const deleteSavedFacility = async id => {
  const response = await client.delete(`/api/ui-services/facilities/${id}`, {
    baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
  });

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Could not delete saved facility');
  }

  return getResponseData(response);
};
