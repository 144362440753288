export const AXIOS_CORE_BASIC_CONFIG = {
  baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  retry: 1,
};

/* Excluded urls list. Add an endpoint to the array to prevent auth token
 from adding to a request */
export const OMITTED_URLS = [
  `/v1/tokenAuth`,
  `maps.googleapis.com/maps/api/geocode`,
];
