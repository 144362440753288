import { createTheme } from '@mui/material/styles';
import theme from '@benniehealth/theme';
import deepmerge from '@mui/utils/deepmerge';

export const localTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: '24px',
        },
        contained: {
          borderRadius: '24px',
        },
      },
    },
  },
});

const mergedTheme = deepmerge(localTheme, theme);

export default mergedTheme;
