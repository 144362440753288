import {
  curatedBlogPostsQuery,
  telehealthLogoQuery,
} from '@benniehealth/core-js';

import { isProd } from '../../helpers/environment';
import { coreClient as client } from '../../helpers/requests/client';
import { responseDataAdapter } from '../../helpers/requests/bennieFetch';

export const fetchBlogPosts = ({ queryParams }) => {
  const query = curatedBlogPostsQuery(
    queryParams,
    !isProd(window.location.hostname),
  );

  return client
    .post(
      `/api/bennie-cms/blog-content`,
      { query },
      { baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL },
    )
    .then(response => responseDataAdapter(response));
};

export const fetchTelehealthLogoQuery = ({ providerIds }) => {
  const query = telehealthLogoQuery(
    providerIds,
    !isProd(window.location.hostname),
  );

  return client
    .post(
      `/api/bennie-cms/app-content`,
      { query },
      { baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL },
    )
    .then(response => responseDataAdapter(response));
};
