import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import createReducer from './reducers';
import rootSaga from './store/saga';
import { bennieApi } from './store/bennieApi';

export const history = createBrowserHistory();

const saga = createSagaMiddleware();

const rootReducer = createReducer();

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      saga,
      bennieApi.middleware,
    ),
});

saga.run(rootSaga);

export default store;
