import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const UnsupportedBrowser = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box p={4} maxWidth="600px">
        <Typography variant="h3" sx={{ lineHeight: '24px' }} color="primary">
          You are viewing Bennie in a browser that we do not currently support.
          While we work to support a wider range of browsers, please consider
          viewing in{' '}
          <a href="https://www.google.com/chrome/" target="_blank">
            Chrome
          </a>
          ,{' '}
          <a href="https://support.apple.com/downloads/safari" target="_blank">
            Safari
          </a>
          , or{' '}
          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
            Firefox
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default UnsupportedBrowser;
