import { coreClient as client } from '../../helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '../../helpers/requests/bennieFetch';

export const saveSession = async () => {
  const response = await client.post(
    '/api/ui-services/sessions/web',
    {},
    {
      baseURL: process.env.BENNIE_API_GATEWAY_BASE_URL,
    },
  );
  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error saving session');
  }

  return getResponseData(response);
};
